.kanban-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 20px;
}

.custom-width {
  width: 15rem; /* You can adjust the width value as needed */
}

.scrollable-board {
  display: flex;
  max-width: 100%;
  margin-top: 20px;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  .scrollable-board {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
}

.column {
  flex: 0 0 300px;
  margin: 0 10px;

  border-radius: 5px;
  padding: 10px;

}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  .column {
    display: flex;
    flex-direction: column;
    width: 20rem;
  }
}

.task-list {
  height: 400px; /* Set your desired height */
  overflow-y: auto;
  border: 2px solid #90E0EF;
  border-radius: 5px;
  padding: 1rem;
  width: 16rem;
  background-color: white;
}

.task {
  margin: 5px 0;
  padding: 8px;
  
  background-color:white;
  border: 1px solid #90E0EF ;
  border-radius: 5px;
  border-left: 6px solid  #00B4D8; /* Add blue border to the right side */
}

.task-content {
  display: flex;
  flex-direction: column;
}

.task-title {
  font-weight: 500;
}

.task-sprint {
  font-size: 12px;
  color: white;
  background-color:#ff6666;
  width:max-content;
  padding: 2px;

}
.task-sprint1{
  font-size: 12px;
  color: white;
  background-color:rgb(129, 129, 3);
  width:max-content;
  padding: 2px;

}
.task-sprint2 {
  font-size: 12px;
  color: white;
  background-color:green;
  width:max-content;
  padding: 2px;

}

/* Add bottom borders to headings */
